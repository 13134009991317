import axios from "axios";
import { useEffect, useState } from "react";

const useOPXPrice =() => {
  const [opxPrice, setOPXPrice] = useState("-");
  const fetchOPxPrice = async () => {
    const res = await axios.get(
      `https://api.opx.finance/opx/token-info?token=OPX&chainCode=Optimism`
    );    
    if (res.data?.OPX?.price) {
      setOPXPrice(
        res.data?.OPX?.price,
      );
    }
  } 
  useEffect(() => {
    fetchOPxPrice()
    const clear = setInterval(async () => {
      fetchOPxPrice()
  }, 30000)
  return () => clearInterval(clear)
  }, [])
  return opxPrice
}
export default useOPXPrice;
