import { FC, memo } from "react";
import styled from "styled-components";

import { DesktopOnlyView, MobileOrTabletView } from "components/Media";
import NotificationContainer from "constants/NotificationContainer";
import { MobileScreenContainer } from "styles/common";

import Banner from "../HomeLayout/Banner";

import Footer from "./Footer";
import Header from "./Header";
import MobileUserMenu from "./Header/MobileUserMenu";
import Head from "next/head";

type AppLayoutProps = {
  children: React.ReactNode;
};

const AppLayout: FC<AppLayoutProps> = memo(({ children }) => {
  return (
    <AppLayoutContainer>
      <Head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
          rel="stylesheet"
        />
      </Head>
      <DesktopOnlyView>
        <DesktopGridContainer>
          <Header />
          <main>{children}</main>
          <Footer />
        </DesktopGridContainer>
      </DesktopOnlyView>
      <MobileOrTabletView>
        <MobileScreenContainer>
          <Banner />
          {children}
          <MobileUserMenu />
        </MobileScreenContainer>
      </MobileOrTabletView>
      <NotificationContainer />
    </AppLayoutContainer>
  );
});

const AppLayoutContainer = styled.div`
  height: 100%;
  /* background-color: #1f2024; */
  > div {
    height: 100%;
  }
  background-image: url("/images/bg.png");
  /* background-position-y: -5px; */
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: scroll;
`;

const DesktopGridContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template: auto 1fr auto / 100%;

  > main {
    display: flex;
    min-height: 0;
    width: 100%;

    > div {
      width: 100%;
      height: 100%;
    }
  }
`;

export default AppLayout;
